import { Link } from "gatsby";
import React, { useState } from "react";
import ContactInfo from "./contact-info";
import Logo from "../images/logo-ben-boonen.inline.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="shadow-sm bg-gray-100 w-full">
      <div className="flex flex-wrap items-end justify-between max-w-screen-xl p-4 mx-auto md:px-8">
        <Link to="/" aria-label="Logo Ben Boonen">
          <Logo className="h-16 max-w-full" />
        </Link>

        <button
          className="flex items-center px-3 py-2 text-gray-900 border border-gray-200 hover:bg-gray-200 rounded md:hidden ml-auto mt-4"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
        <div className="w-full md:hidden"></div>
        <ContactInfo />
      </div>
      <div className="flex flex-wrap items-center justify-center bg-gray-900">
        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } uppercase font-medium tracking-wider block md:flex md:items-center w-full md:w-auto p-1 mx-auto md:px-8`}
        >
          {[
            {
              route: `/`,
              title: `Home`,
            },
            {
              route: `/#diensten`,
              title: `Diensten`,
            },
            {
              route: `/projecten`,
              title: `Projecten`,
            },
            {
              route: `/partners`,
              title: `Partners`,
            },
            {
              route: `/#contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="block p-2 text-red-100 no-underline md:inline-block md:mt-0 md:ml-6 hover:text-gray-600"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
