import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

function SEO({ description, lang, meta, keywords, title, image, imageAlt }) {
  const { site } = useStaticQuery(detailsQuery);

  const metaKeywords =
    (keywords && keywords.length > 0 && keywords) || site.keywords || [];
  const metaDescription = description || site.openGraph.description || "";
  const siteTitle = site.openGraph.title || "";
  const metaImage =
    (image &&
      image.asset &&
      imageUrlFor(buildImageObj(image)).width(1200).url()) ||
    (site.openGraph.image &&
      site.openGraph.image.asset &&
      imageUrlFor(buildImageObj(site.openGraph.image)).width(1200).url()) ||
    "";

  const pageTitle = title || siteTitle;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={pageTitle}
      titleTemplate={pageTitle === siteTitle ? siteTitle : `%s | ${siteTitle}`}
      meta={[
        // {
        //   name: "google-site-verification",
        //   content: "7MfJFsxBVui5UlEBExUFeMW9-Q6g9fPgoaxwzvbqaV0",
        // },
        {
          name: "description",
          content: metaDescription,
        },
        {
          property: "og:url",
          content: site.openGraph.websiteUrl || "",
        },
        {
          property: "og:title",
          content: siteTitle,
        },
        {
          property: "og:description",
          content: metaDescription,
        },
        {
          property: "og:type",
          content: "website",
        },
        {
          property: "og:image",
          content: metaImage,
        },
        {
          property: "og:image:alt",
          content: imageAlt || site.openGraph.image.alt,
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
        {
          name: "twitter:creator",
          content: title,
        },
        {
          name: "twitter:title",
          content: siteTitle,
        },
        {
          name: "twitter:description",
          content: metaDescription,
        },
        {
          property: "twitter:image",
          content: metaImage,
        },
        {
          property: "twitter:image:alt",
          content: imageAlt || site.openGraph.image.alt,
        },
      ]
        .concat(
          metaKeywords && metaKeywords.length > 0
            ? {
                name: "keywords",
                content: metaKeywords.join(", "),
              }
            : []
        )
        .concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `nl`,
  keywords: [],
  meta: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  lang: PropTypes.string,
  meta: PropTypes.array,
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      openGraph {
        title
        description
        image {
          ...SanityImage
          alt
        }
        websiteUrl
      }
      keywords
    }
  }
`;
