import React from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import TelephoneNumber from "./telephone-number";
import Email from "./email";
import FacebookLogo from "../images/facebook-logo.inline.svg";

const Footer = () => {
  const { page, site } = useStaticQuery(detailsQuery);
  const diensten = page.content.filter(
    (c) => c.__typename === "SanityServiceCollection"
  );
  const dienstSlugs =
    (diensten &&
      diensten.length > 0 &&
      diensten[0].items.map((dienst) => ({
        key: dienst._key,
        title: dienst.heading,
        slug: `#${dienst.slug.current}`,
      }))) ||
    [];
  return (
    <footer className="bg-blue-branding px-4 p-8 sm:text-left md:px-8 lg:px-16 xl:px-16 xl:pt-8 text-gray-200">
      <div className="container mx-auto leading-tight md:flex">
        <div className="md:w-1/3 px-4 py-4">
          <h3 className="text-sm text-gray-300 font-bold uppercase">
            {site.contactInfo.companyName}
          </h3>
          <div className="mt-4 text-gray-500">
            <p>{site.siteMetadata.description}</p>
          </div>
        </div>

        <div className="md:w-1/3 px-4 py-4">
          <h3 className="text-sm text-gray-300 font-bold uppercase">
            Contacteer ons
          </h3>
          <div className="mt-4 text-gray-500">
            <TelephoneNumber number={site.contactInfo.telephoneNumber} />
            <Email email={site.contactInfo.email} />
            <div className="flex items-center justify-center md:justify-start mt-4">
              <a
                target="_blank"
                rel="noreferrer"
                href={site.contactInfo.facebookUrl}
                className="hover:text-gray-700"
                aria-label="Link to Facebook page"
              >
                <FacebookLogo className="fill-current w-12 h-12 md:w-8 md:h-8 align-middle" />
              </a>
            </div>
          </div>
        </div>

        <div className="md:w-1/3 px-4 py-4">
          <h3 className="text-sm text-gray-300 font-bold uppercase">
            Diensten
          </h3>
          <div className="mt-4 text-gray-500">
            {(dienstSlugs &&
              dienstSlugs.length > 0 &&
              dienstSlugs.map((d) => (
                <Link
                  className="hover:text-gray-300 block pr-2 py-4 md:pt-0 md:pb-2"
                  key={d.key}
                  to={d.slug}
                >
                  {d.title}
                </Link>
              ))) ||
              null}
          </div>
        </div>
      </div>
      <div className="text-gray-600 text-sm text-center">
        © {new Date().getFullYear()} Ben Boonen | All rights reserved
      </div>
    </footer>
  );
};

export default Footer;
const detailsQuery = graphql`
  query FooterQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      contactInfo {
        email
        facebookUrl
        telephoneNumber
        vatNumber
        companyName
      }
      title
      siteMetadata: openGraph {
        description
      }
    }
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      content {
        ... on SanityServiceCollection {
          items {
            slug {
              current
            }
            heading
            _key
          }
        }
      }
    }
  }
`;
