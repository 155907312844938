import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, mainClass }) => {
  return (
    <div className="bg-gray-100 flex flex-col min-h-screen font-sans text-gray-900">
      <Header />

      <main className={`flex-1 w-full ${mainClass || ""}`}>{children}</main>

      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mainClass: PropTypes.string,
};

export default Layout;
