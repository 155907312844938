import React from "react";
import PropTypes from "prop-types";

const TelephoneNumber = ({ number }) => (
  <a
    href={`tel:${number}`}
    className="flex items-center hover:text-gray-700 hover:underline h-12 md:h-auto"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="h-4 w-4 fill-current text-red-branding"
    >
      <path d="M13.04 14.69l1.07-2.14a1 1 0 0 1 1.2-.5l6 2A1 1 0 0 1 22 15v5a2 2 0 0 1-2 2h-2A16 16 0 0 1 2 6V4c0-1.1.9-2 2-2h5a1 1 0 0 1 .95.68l2 6a1 1 0 0 1-.5 1.21L9.3 10.96a10.05 10.05 0 0 0 3.73 3.73zM8.28 4H4v2a14 14 0 0 0 14 14h2v-4.28l-4.5-1.5-1.12 2.26a1 1 0 0 1-1.3.46 12.04 12.04 0 0 1-6.02-6.01 1 1 0 0 1 .46-1.3l2.26-1.14L8.28 4z" />
    </svg>
    <span className="ml-1">{number}</span>
  </a>
);

TelephoneNumber.propTypes = {
  number: PropTypes.string.isRequired,
};

export default TelephoneNumber;
