import React from "react";
import PropTypes from "prop-types";

const Email = ({ email }) => (
  <a
    href={`mailto:${email}`}
    className="flex items-center hover:text-gray-700 hover:underline md:pt-1 h-12 md:h-auto"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className="h-4 w-4 fill-current text-red-branding"
    >
      <path d="M4 4h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6c0-1.1.9-2 2-2zm16 3.38V6H4v1.38l8 4 8-4zm0 2.24l-7.55 3.77a1 1 0 0 1-.9 0L4 9.62V18h16V9.62z" />
    </svg>
    <span className="ml-1">{email}</span>
  </a>
);

Email.propTypes = {
  email: PropTypes.string.isRequired,
};

export default Email;
