import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import TelephoneNumber from "./telephone-number";
import Email from "./email";

const ContactInfo = () => {
  const { site } = useStaticQuery(detailsQuery);

  const telephoneNumber = site.contactInfo.telephoneNumber;
  const email = site.contactInfo.email;
  return (
    <div className="mx-auto md:mx-0 pt-4 md:pt-0">
      <TelephoneNumber number={telephoneNumber} />

      <Email email={email} />
    </div>
  );
};

export default ContactInfo;

const detailsQuery = graphql`
  query ContactInfoQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      contactInfo {
        email
        telephoneNumber
      }
    }
  }
`;
